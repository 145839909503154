// Slick carousel - shared features and fixes

.slick-slider {
  user-select: text;

  .slick-track,
  .slick-list {
    perspective: 1000px; // https://github.com/kenwheeler/slick/pull/2230
  }

  .slick-list {
    &.draggable {
      user-select: none;
    }

    &:not(.draggable) {
      .slick-slide {
        outline: none;
      }
    }
  }
}
