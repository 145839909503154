$m-articles-vertical-action-mobile-height: 50px;
$m-articles-vertical-action-gfb-width: 100px;
$content-regular-width: null !default;

.m-articles {
  &--vertical,
  &--vertical-reversed {
    max-width: $content-regular-width;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);;
    margin-bottom: ($grid-gutter-height / -1);

    + .m-articles__list {
      margin-top: $grid-gutter-height;
    }

    &--magazine {
      @media (min-width: $screen-sm-min) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
      }

      @media (min-width: $screen-xlg-min) {
        grid-template-rows: 310px auto;
      }
    }

    &.slick-slider {
      display: block; // fix wrong slides width (probably caused by wrong flexbox support in Slick carousel)

      .slick-track {
        margin-left: 0; // align to the left
      }
    }

    &--vertical,
    &--vertical-reversed {
      flex-direction: column;
    }
  }

  &__item {
    //margin-bottom: $base-spacing; // disabled since auto slider
    position: relative;
    padding: 0 floor($grid-gutter-width / 2) $grid-gutter-height;
    flex: 1 1 (100% / 3);
    outline: none;

    @at-root .m-articles__list--gte4 & {
      flex-basis: (100% / 4);
    }

    @at-root .m-articles__list--magazine & {
      @media (min-width: $screen-sm-min) {
        display: flex;
        flex: initial;

        &:nth-child(1) {
          display: block;
          grid-row: 1 / 3;
          grid-column: 1;
        }

        &:nth-child(2) {
          grid-row: 1;
          grid-column: 2 / 4;
        }

        &:nth-child(3) {
          grid-row: 2;
          grid-column: 2 / 4;
        }

        &:nth-child(n+4) {
          display: block;
          padding-top: $grid-gutter-height;
          grid-row: initial;
          grid-column: initial;
        }
      }
    }

    &--vertical,
    &--vertical-reversed {
      display: flex;
      flex-direction: column;
      padding-top: $base-spacing;
      padding-left: floor($grid-gutter-width / 2);
      padding-right: floor($grid-gutter-width / 2);
      margin-top: floor($base-spacing / 2);
      margin-bottom: floor($base-spacing / 2);

      text-align: center;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: row;
        text-align: left;
      }
    }

    &--vertical {
      padding-bottom: $m-articles-vertical-action-mobile-height;
      border: 1px solid currentColor;
      border-radius: $border-radius-base;

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-bottom: $base-spacing;
        padding-right: $m-articles-vertical-action-gfb-width + floor($base-spacing / 2);
      }
    }

    &--vertical-reversed {
      padding-bottom: $base-spacing;

      &:nth-child(even) {
        @media screen and (min-width: $grid-float-breakpoint) {
          flex-direction: row-reverse;
          text-align: right;
        }
      }
    }
  }

  &__media {
    @at-root .m-articles__list--magazine & {
      @media (min-width: $screen-sm-min) {
        min-width: 29vw;
        width: 29vw;
        margin-right: $grid-gutter-width;

        @at-root .m-articles__list--magazine .m-articles__item:first-child .m-articles__media {
          @media (min-width: $screen-sm-min) {
            margin-right: 0;
          }
        }
      }

      @media (min-width: $screen-lg-min) {
        min-width: 370px;
        width: 370px;
      }
    }

    .m-articles__item--vertical &,
    .m-articles__item--vertical-reversed & {
      margin: 0 auto floor($base-spacing / 2) auto;

      @media screen and (min-width: $grid-float-breakpoint) {
        margin: 0 floor($base-spacing / 2);
      }
    }
  }

  &__img {
    object-fit: cover;

    .m-articles__item--vertical &,
    .m-articles__item--vertical-reversed & {
      max-width: max-content;
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
  }

  &__header {
    margin-bottom: $base-spacing;
  }

  &__date {
    margin-top: 5px;
    font-style: italic;
  }

  &__indicators {
    margin-top: ceil($base-spacing * 2);
  }

  &__action {
    .m-articles__item--vertical & {
      position: absolute;
      right: 0;
      left: 0;
      bottom: -1px;
      height: $m-articles-vertical-action-mobile-height;

      @media screen and (min-width: $grid-float-breakpoint) {
        right: 0;
        left: auto;
        top: 0;
        bottom: 0;
        height: 100%;
        width: $m-articles-vertical-action-gfb-width;
      }
    }
  }

  &__btn-special {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: floor($base-spacing / 2);
    border-radius: 0 0 $btn-border-radius-base $btn-border-radius-base;
    white-space: normal;

    @media screen and (min-width: $grid-float-breakpoint) {
      border-radius: 0 $btn-border-radius-base $btn-border-radius-base 0;
    }
  }
}
