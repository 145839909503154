.modal-dialog {
  &--expanded {
    @media screen and (min-width: $screen-sm-min) {
      margin: 15px;
      width: auto;
    }

    @media screen and (min-width: $screen-lg-min) {
      margin: 30px; // the same as other modals
    }
  }

  &--with-image {
    @supports (object-fit: cover) {
      $image-space-width: 160px;
      max-width: 95vw;

      @media (min-width: $screen-sm-min) {
        width: $modal-md + $image-space-width;

        .modal-content {
          padding-left: $image-space-width;
        }
      }

      @at-root .modal-dialog__image {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: block;
          position: absolute;
          top: 1px;
          left: 1px;
          bottom: 1px;
          height: 100%; // simple fallback
          height: calc(100% - 2px);
          width: $image-space-width - 1px; // additional space for the border
          object-fit: cover;
          object-position: 0 0;
          border-radius: $modal-dialog-border-radius 0 0 $modal-dialog-border-radius;
        }
      }
    }
  }

  &__inner-mb {
    margin-bottom: nth($modal-inner-padding, 1);
  }
}

.modal-header {
  .close {
    margin-top: -4px;
  }
}

.modal-footer {
  &__actions {
    margin-top: -5px;
    margin-left: -5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .creator__errors,
    .btn {
      margin-top: 5px;
      margin-left: 5px;
      flex: 1 1 0;
    }

    .creator__errors {
      flex-basis: auto; // break it to the new line
    }

    // Regular layout from small screen viewport.
    &--ssm-regular {
      @media (min-width: $screen-sm-min) {
        display: block;

        .creator__errors {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }

  &--sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    z-index: $zindex-modal + 5;
    border-radius: 0 0 $modal-dialog-border-radius $modal-dialog-border-radius;
  }
}

.modal-title {
  text-transform: $modal-title-text-transform;
}

// Special utility class for allergens popover, because it triggers a modal and therefore requires a lower z-index value.
// Class name is general so it can be used for other similar cases.
.modal-open .u-modal-trigger {
  z-index: $zindex-modal-background - 1;
}
