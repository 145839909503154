img {
  @include img-responsive();
}

.m-page-subheader {
  margin-bottom: map-get($spacers, 6);
  text-align: center;

  &__title {
    display: block;
    @extend .h1;
  }

  &__sup-title {
    @extend .h3;
  }

  &__description {
    margin-bottom: floor($base-spacing / 2);
  }
}

.content {
  max-width: $content-regular-width;
  margin-left: auto;
  margin-right: auto;
}

.content-content {
  margin: $base-spacing 0;
}

.btn,
button,
a {
  transition: color, background-color, opacity, border-color, font-weight;
  transition-duration: $base-duration;
}

.mobile-navigation a {
  color: inherit;
}

.select-restaurant-before-content {
  @media screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

// TODO: refactor .lb-disable-scrolling - remove redundant occurrence of classes & check that body need be overflow hidden
.lb-disable-scrolling {
  overflow: hidden;

  body {
    overflow: revert;
  }
}

p {
  margin-bottom: $base-spacing;
}
