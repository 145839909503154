.m-testimonials {
  width: 100%;

  &--messages {
    max-width: $content-condensed-width;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &--boxes,
    &--quotes {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &--quotes {
      justify-content: center;
    }

    &.slick-slider {
      display: block; // fix wrong slides width (probably caused by wrong flexbox support in Slick carousel)

      .slick-track {
        margin-left: 0; // align to the left
      }
    }
  }

  &__item {
    padding: $base-spacing;

    &--messages {
      position: relative;
      margin: $base-spacing 0;
      width: 100%;
      border-radius: 24px;
      min-width: 30%;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: auto;
        bottom: -$base-spacing;
        border-width: floor($base-spacing / 2);
        border-style: solid;
      }

      &:nth-child(odd) {
        align-self: flex-start;
        color: #fff;

        &::after {
          left: 20px;
          right: auto;
        }
      }

      &:nth-child(even) {
        align-self: flex-end;
        color: #000;

        &::after {
          left: auto;
          right: 20px;
        }
      }

      @media screen and (min-width: $grid-float-breakpoint) {
        max-width: 70%;
        width: auto;
      }
    }

    &--boxes {
      border-radius: $border-radius-base;
      border: 1px solid currentColor;
      margin-top: floor($base-spacing / 2);

      @media screen and (min-width: $grid-float-breakpoint) {
        margin: floor($base-spacing / 2);
        flex: 1 1 calc((100% / 3 ) - #{$base-spacing});
      }
    }

    &--quotes {
      margin-top: floor($base-spacing / 2);
      text-align: center;

      &::before,
      &::after {
        display: block;
        height: 34px;
        font-size: 54px;
        line-height: 1;
      }

      &::before {
        content: "\201C";
        text-align: left;
      }

      &::after {
        content: "\201D";
        text-align: right;
      }

      @media screen and (min-width: $grid-float-breakpoint) {
        margin: floor($base-spacing / 2);
        flex: 1 1 calc((100% / 3 ) - #{$base-spacing});
      }
    }
  }

  &__comments {
    margin-top: $base-spacing;
  }

  &__comments-item {
    padding-top: $base-spacing;
    border-top: 1px solid currentColor;
    text-align: right;

    .m-testimonials__item--quotes & {
      text-align: center;
    }
  }

  &__desc {
    margin-bottom: floor($base-spacing / 2);

    .m-testimonials__item--quotes & {
      font-weight: $font-weight-bold;
    }
  }

  &__author {
    display: block;
    text-align: right;
    font-weight: $font-weight-bold;

    .m-testimonials__item--quotes & {
      font-weight: $font-weight-base;
      text-align: center;
    }
  }

  &__time {
    display: block;
    text-align: right;
    font-style: italic;

    .m-testimonials__item--quotes & {
      text-align: center;
    }
  }

  &__indicators {
    text-align: center;
    margin-top: $base-spacing;
  }
}
