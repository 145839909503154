.m-layout {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
  "header"
  "content";

  @media screen and (min-width: $grid-float-breakpoint) {
    grid-template-columns: ($desktop-nav-width + $m-layout-header-scrollbar-width) minmax(0, 1fr);
    grid-template-areas:
    "header content";
  }

  &--header-right {
    @media screen and (min-width: $grid-float-breakpoint) {
      grid-template-columns: minmax(0, 1fr) ($desktop-nav-width + $m-layout-header-scrollbar-width);
      grid-template-areas:
      "content header";
    }
  }

  &__header {
    height: $m-layout-header-mobile-height;
    grid-area: header;

    @media screen and (min-width: $grid-float-breakpoint) {
      height: 100vh;
      position: sticky;
      top: 0;
    }

    &--with-offset {
      @media screen and (min-width: $grid-float-breakpoint) {
        top: $m-layout-offset-size;
        left: $m-layout-offset-size;
        bottom: $m-layout-offset-size;
        width: calc(100% - #{$m-layout-offset-size});
        height: calc(100vh - (#{$m-layout-offset-size} * 2));
  
        .m-layout--header-right & {
          left: revert;
          right: $m-layout-offset-size;
        }
      }
    }
  }

  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
  }

  &__cover-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  &__cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
