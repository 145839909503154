@import "bootstrap-v4";

//
// Additional features for Bootstrap 3
// --------------------------------------------------
// NOTE: Most of the content is the same as `pr-admin/app/styles/_bootstrap-ext.scss`

//
// Grid system
// --------------------------------------------------
.row {
  &--flex {
    // Some flexbox grid configurations doesn't work as expected in Safari (tested with Safari 10.1.1 on OS X 10.11.6) and IE11, e.g. auto wrap for `.col` elements doesn't work (different bugs on Safari and IE).
    // Note that all features using this grid require test with different browsers and viewports.
    // More info and an example: https://jsfiddle.net/damianwajer/q1fLwjmo
    // Bootstrap 4 issue: https://github.com/twbs/bootstrap/issues/21967
    // Example workaround: https://github.com/twbs/bootstrap/pull/22271/commits/eece33216d2400e5502706cab5217f59b887e397 (doesn't work for all cases)
    display: flex;
    flex-wrap: wrap;

    [class^=col-] {
      float: none;
      width: 100%;
    }
  }

  // Auto-layout columns
  // Flex item should be used within `row` element.
  .col-auto,
  .col-xs-auto,
  .col-sm-auto,
  .col-md-auto,
  .col-lg-auto,
  .col,
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
  .col-xs,
  .col-sm,
  .col-md,
  .col-lg {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-left: floor($grid-gutter-width / 2);
    padding-right: ceil($grid-gutter-width / 2);
    max-width: 100%;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }

  // Support standard grid by overriding flex grid if specific classes were used.
  @for $i from 1 through $grid-columns {
    .col-#{$i} {
      flex: 0 0 percentage(($i / $grid-columns));
      max-width: percentage(($i / $grid-columns));
    }
  }

  @media (min-width: $screen-xs-min) {
    .col-xs {
      flex-basis: 0;
      flex-grow: 1;
    }

    .col-xs-auto {
      flex: 0 0 auto;
      width: auto;
    }

    @for $i from 1 through $grid-columns {
      .col-xs-#{$i} {
        flex: 0 0 percentage(($i / $grid-columns));
        max-width: percentage(($i / $grid-columns));
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
    }

    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }

    @for $i from 1 through $grid-columns {
      .col-sm-#{$i} {
        flex: 0 0 percentage(($i / $grid-columns));
        max-width: percentage(($i / $grid-columns));
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
    }

    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }

    @for $i from 1 through $grid-columns {
      .col-md-#{$i} {
        flex: 0 0 percentage(($i / $grid-columns));
        max-width: percentage(($i / $grid-columns));
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
    }

    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }

    @for $i from 1 through $grid-columns {
      .col-lg-#{$i} {
        flex: 0 0 percentage(($i / $grid-columns));
        max-width: percentage(($i / $grid-columns));
      }
    }
  }

  &--no-gutter {
    margin-right: 0;
    margin-left: 0;

    > [class*="col"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Simple solution for vertical gutter in grid cols.
  &--vertical-gutter {
    > [class*="col"] {
      padding-top: floor($grid-gutter-height / 2);
      padding-bottom: floor($grid-gutter-height / 2);
    }
  }
}

// TODO: Add flex grid and flex utilities from Bootstrap 4 (with grid mixins).

//
// Forms
// --------------------------------------------------
.form-control {
  box-shadow: $input-box-shadow;
  border: $input-border-shorthand;
  font-size: $form-control-font-size-base;
  height: $form-control-input-height-base;

  &--inline {
    display: inline-block;
    width: auto;
  }
}

label {
  font-weight: $labels-font-weight;
}

//
// Labels
// --------------------------------------------------
.label {
  padding: $labels-padding;
  text-transform: $labels-text-transform;
  font-weight: $labels-font-weight;
  border-radius: $labels-border-radius;
}

//
// Legend
// --------------------------------------------------
legend {
  margin-bottom: $legend-margin-bottom;
  font-size: $legend-font-size;
  font-weight: $legend-font-weight;
  border: $legend-border;
}

//
// Close
// --------------------------------------------------
.close {
  font-size: $close-font-size;
}

//
// Colors
// --------------------------------------------------
.text-success {
  color: var(--text-success, #{$state-success-text});
}

.text-danger {
  color: var(--text-danger, #{$state-danger-text});
}

.text-warning {
  color: var(--text-warning, #{$state-warning-text});
}

.text-muted {
  color: var(--text-muted, #{$text-muted});
}

//
// Collapse
// --------------------------------------------------
.collapsed {
  &__show {
    display: none;
  }
}

.collapsed {
  .collapsed__show {
    display: block;
  }

  .collapsed__hide {
    display: none;
  }
}

//
// Buttons
// --------------------------------------------------
.btn {
  @include button-size($btn-padding-base-vertical, $btn-padding-base-horizontal, $btn-font-size-base, $btn-line-height-base, $btn-border-radius-base);
  text-transform: $btn-text-transform;
  box-shadow: $btn-box-shadow;
}

.btn-sm {
  @include button-size($btn-padding-small-vertical, $btn-padding-small-horizontal, $btn-font-size-small, $btn-line-height-small, $btn-border-radius-small);
}

.btn-lg {
  @include button-size($btn-padding-large-vertical, $btn-padding-large-horizontal, $btn-font-size-large, $btn-line-height-large, $btn-border-radius-large);
}

// Base button with large size starting from grid float breakpoint
.btn-gfb-lg {
  @media screen and (min-width: $grid-float-breakpoint) {
    @include button-size($btn-padding-large-vertical, $btn-padding-large-horizontal, $btn-font-size-large, $btn-line-height-large, $btn-border-radius-large);
  }
}

.btn-wrap {
  white-space: normal !important;
}

button.close,
.close {
  margin-top: -4px;
  margin-right: -5px;
  margin-left: 5px;
  padding: 4px 10px;
}

button[disabled] {
  cursor: not-allowed;
}

//
// Popovers
// --------------------------------------------------
.popover-title {
  .close {
    margin-top: -10px;
    margin-right: -10px;
  }
}

//
// Dropdown menus
// --------------------------------------------------
.dropdown-menu {
  max-width: 80vw;
  overflow-x: auto; // In most cases combined with `max-width` and correct position, it allows the user to access even too long content with nowrap property (edge-cases are supported by utils/Dropdown.js).
  font-size: $dropdown-font-size;

  > li {
    > a {
      padding: $dropdown-link-padding;

      @if $dropdown-link-padding-mobile != $dropdown-link-padding {
        @media (max-width: $grid-float-breakpoint-max) {
          padding: $dropdown-link-padding-mobile;
        }
      }
    }
  }
}

.dropdown-menu-center {
  right: 0;
  left: 0;
}

//
// Wells
// --------------------------------------------------
.well {
  color: $well-text-color;

  &__title {
    margin-top: 0;
    color: $well-title-color;
  }
}

//
// Modal
// More dialog styles in: `client/css/components/modals.scss`
// --------------------------------------------------
.modal-dialog {
  border-radius: $modal-dialog-border-radius;
}

//
// Media object
// --------------------------------------------------
.media {
  > [class*="float-"] {
    display: inline-block; // Avoid unwanted spacing under defined float breakpoint.

    // Set horizontal padding for the floated element the same as `margin-top` value from media object (15px by default) using float-*-right and float-*-left notation form Bootstrap 4.
    // If we need to customize `margin-top` for media object we can add it here as a variable and override `bootstrap/_media.scss` (unfortunately there is no variable for it in Bootstrap).
    &[class*="-right"] {
      padding-left: floor($grid-gutter-width / 2);
    }

    &[class*="-left"] {
      padding-right: floor($grid-gutter-width / 2);
    }
  }
}

//
// Responsive utilities
// --------------------------------------------------
@media (max-width: $screen-xs-min - 1) {
  @include responsive-invisibility('.hidden-xxs');
}

.visible-xlg-block,
.visible-xlg-inline,
.visible-xlg-inline-block,
.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block {
  display: none !important;
}

.visible-xxs-block {
  @media (max-width: $screen-xs-min - 1) {
    display: block !important;
  }
}

.visible-xxs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}

.visible-xxs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-xlg-min) {
  @include responsive-invisibility('.hidden-xlg');
}

.visible-xlg-block {
  @media (min-width: $screen-xlg-min) {
    display: block !important;
  }
}

.visible-xlg-inline {
  @media (min-width: $screen-xlg-min) {
    display: inline !important;
  }
}

.visible-xlg-inline-block {
  @media (min-width: $screen-xlg-min) {
    display: inline-block !important;
  }
}

.invisible {
  &,
  &:before,
  &:after {
    visibility: hidden !important;
  }
}

//
// Screen readers
// --------------------------------------------------

// Only display content to screen readers (fix below should be unnecessary in Bootstrap 4)
.sr-only {
  white-space: nowrap; // Prevent wrapping content to one word per line: https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
}

//
// Typography
// --------------------------------------------------

@if $headings-size-breakpoint != null {
  @media (min-width: $headings-size-breakpoint) {
    h1,
    .h1 {
      font-size: $font-size-h1-gfb;
    }

    h2,
    .h2 {
      font-size: $font-size-h2-gfb;
    }

    h3,
    .h3 {
      font-size: $font-size-h3-gfb;
    }

    h4,
    .h4 {
      font-size: $font-size-h4-gfb;
    }

    h5,
    .h5 {
      font-size: $font-size-h5-gfb;
    }
  }
}
