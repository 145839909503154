$m-gallery-carousel-max-width: null !default;
$m-gallery-carousel-screen-lg-max-width: null !default;
$m-gallery-carousel-overlay-icon-font-size: 3rem !default;
$m-gallery-carousel-overlay-icon-color: #fff !default;

.m-gallery-carousel {
  max-width: $m-gallery-carousel-max-width;
  margin: 0 auto;

  @media screen and (min-width: $screen-lg-min) {
    max-width: $m-gallery-carousel-screen-lg-max-width;
  }

  &__wrapper {
    position: relative;
    margin-bottom: $base-spacing / 2;
    padding: 0 $grid-gutter-width;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: $base-spacing;
      padding: revert;
    }
  }

  &__list,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  &__item,
  &__link,
  &__picture,
  &__img {
    visibility: hidden;
    height: 0;
    opacity: 0;

    .slick-initialized &, // prevent "Flash of Unstyled Content" effect
    &:first-child {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }

  &__item {
    &--thumbnails {
      text-align: center;
    }
  }

  &__link {
    position: relative;
    display: block;

    &.is-active,
    &:hover,
    &:focus {
      .m-gallery-carousel__overlay {
        opacity: 1;
      }
    }
  }

  &__overlay {
    @include position(absolute, 0 0 0 0);
    opacity: 0;
    transition: opacity $base-duration;
    background-color: rgba(#000, $hover-opacity);
  }

  &__overlay-icon {
    &,
    &:before {
      @include u-center;
      margin: 0;
      font-size: $m-gallery-carousel-overlay-icon-font-size;
      color: $m-gallery-carousel-overlay-icon-color;
    }
  }

  &__indicators {
    margin-top: $base-spacing / 2;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: $base-spacing;
    }
  }

  &__arrow {
    @extend %button-unstyled;
    @include position(absolute, 0 null 0 null);
    font-size: 36px;
    z-index: 1;

    &--circle {
      height: 30px;
      width: 30px;
      margin: auto 0;
      font-size: 18px;
      border: 1px solid currentColor;
      border-radius: 50%;
    }

    &--left {
      left: -6px;

      @media screen and (min-width: $grid-float-breakpoint) {
        left: -40px;
      }
    }

    &--right {
      right: -6px;

      @media screen and (min-width: $grid-float-breakpoint) {
        right: -40px;
      }
    }
  }

  &__thumbnail {
    margin-right: $base-spacing / 4;
    margin-left: $base-spacing / 4;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-right: $base-spacing / 2;
      margin-left: $base-spacing / 2;
    }
  }
}

// Copied from m-indicators module
.m-gallery-carousel-indicators {
  line-height: 1;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-flex;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 6px;
  }

  &__indicator {
    display: block;
    background-color: rgba(#777, .75);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    border: 1px solid rgba(#fff, .75);

    .m-gallery-carousel-indicators__btn.is-active & {
      border: 1px solid rgba(#777, .75);
    }
  }
}
