$mobile-navigation-fullscreen: false !default;
$mobile-navigation-flex-middle: false !default;
$mobile-navigation-font-size: null !default;
$mobile-navigation-header-margin: 0 !default;
$mobile-navigation-header-padding: 10px !default;
$mobile-navigation-header-bg-color: $brand-primary !default;
$mobile-navigation-bg-color: #f6f6f6 !default;
$mobile-navigation-backdrop-opacity: $modal-backdrop-opacity !default;
$mobile-navigation-backdrop-bg: $modal-backdrop-bg !default;
$mobile-navigation-breakpoint-max: $grid-float-breakpoint-max !default; // use `none` value to disable breakpoint and apply it for all screens
$mobile-navigation-breakpoint: $mobile-navigation-breakpoint-max + 1 !default;
$mobile-navigation-prevent-scrolling: true !default;
$mobile-navigation-list-text-transform: null !default;
$mobile-navigation-list-link-padding: 15px 20px !default;
$mobile-navigation-dropdown-style-disc: true !default;
$mobile-navigation-dropdown-menu-font-size: null !default;

.mobile-navigation-toggle {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 10;

  @if $mobile-navigation-breakpoint-max != none {
    @media screen and (min-width: $mobile-navigation-breakpoint) {
      display: none;
    }
  }
}

.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  font-size: $mobile-navigation-font-size;
  overflow: auto;
  z-index: $zindex-mobile-navigation;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
  animation-timing-function: ease-out;
  background-color: $mobile-navigation-bg-color;
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: transform $base-duration, visibility $base-duration;

  @if $mobile-navigation-flex-middle == true {
    display: flex;
    flex-direction: column;
  }

  @if $mobile-navigation-fullscreen == true {
    right: 0;
  } @else {
    min-width: 200px;
    max-width: 80vw;
  }

  @if $mobile-navigation-breakpoint-max != none {
    @media screen and (min-width: $mobile-navigation-breakpoint) {
      display: none;
    }
  }

  &--right {
    left: if($mobile-navigation-fullscreen == true, 0, auto);
    right: 0;
    transform: translate3d(100%, 0, 0);
  }

  &.is-open {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  &__header {
    @include clearfix;
    position: relative;
    margin: $mobile-navigation-header-margin;
    padding: $mobile-navigation-header-padding;
    background-color: $mobile-navigation-header-bg-color;
    color: #fff;
    font-size: 16px;

    .mobile-navigation-toggle {
      @extend %button-unstyled;
      position: relative;
      float: right;
      font-family: $font-family-sans-serif; // font-family for `X` icon (&times;)
      z-index: 1;
      padding: 0 10px 2px 10px;
      color: inherit;
      font-size: 25px;
      line-height: 1;
      width: 40px;
      height: 30px;

      &:hover,
      &:focus {
        color: inherit;
        opacity: $hover-opacity;
      }
    }

    a {
      display: inline-block;
      padding: 2px 5px;
      color: inherit;
    }
  }

  &__inline-list {
    @include list-unstyled;
    margin: 0 30px 0 -5px; // add some space for close button
    display: flex;
    flex-wrap: wrap;
  }

  &__inline-list-item {
    padding-right: 5px;
    padding-left: 5px;
  }

  &__list {
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    text-transform: $mobile-navigation-list-text-transform;

    @if $mobile-navigation-flex-middle == true {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }

    > li {
      float: none;

      &:first-of-type > a {
        border: none;
      }

      > a {
        display: block;
        padding: $mobile-navigation-list-link-padding;
      }
    }

    ul.dropdown-menu {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      font-size: $mobile-navigation-dropdown-menu-font-size;
      float: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      margin: 0;
      max-width: none;

      li > a {
        position: relative;
        padding: $mobile-navigation-list-link-padding;
        white-space: normal;

        @if $mobile-navigation-dropdown-style-disc == true {
          padding-left: 3.125em;

          &:before {
            @include position(absolute, 50% null null 1.875em);
            line-height: 0;
            content: "\2022"; // bullet (&bull;)
          }
        }
      }
    }

    .is-active {
      background-color: darken($mobile-navigation-bg-color, 5%);
    }
  }

  &__slogan {
    @include container-fixed();
    text-align: center;
  }

  .dropdown-backdrop {
    display: none;
  }

  .m-language-select {
    &__item {
      padding-right: 8px;
      padding-left: 8px;
    }

    &__locale-image {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.mobile-navigation-backdrop {
  display: none;

  &.is-active {
    @include position(fixed, 0 0 0 0);
    display: block;
    z-index: $zindex-mobile-navigation-background;
    background-color: $mobile-navigation-backdrop-bg;
    opacity: $mobile-navigation-backdrop-opacity;

    @if $mobile-navigation-breakpoint-max != none {
      @media screen and (min-width: $mobile-navigation-breakpoint) {
        display: none;
      }
    }
  }
}

// Name of this component (mobile-navigation) can be misleading, but we don't want to limit ourselves just to mobile navigation, the same navigation can be used also for desktop.
// We should rather change the name to `responsive-navigation`, but we keep it like this for backward compatibility reasons (color variants of legacy themes).
// On body element we use general class name (with `l-` for layout prefix) indicating that navigation is open and depending on whether or not it's just for mobile we prevent body from scrolling on corresponding viewports.
body.l-navigation-open {
  @if $mobile-navigation-prevent-scrolling == true {
    @if $mobile-navigation-breakpoint-max == none {
      overflow: hidden;
    } @else {
      @media screen and (max-width: $mobile-navigation-breakpoint-max) {
        overflow: hidden;
      }
    }
  }
}
