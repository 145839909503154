// Essentials shared variables imports common for all types of themes (mobile_app / active_menu / other www themes)
@import 'base/variables';
@import 'base/functions';
@import '../components/mixins';
@import 'base/animations';
@import '../components/icons';
@import 'base/utilities';
@import 'base/bootstrap-ext';

// Essentials shared components imports common for all types of themes (mobile_app / active_menu / other www themes)
@import '../components/menu_highlights';
@import '../components/cart';
@import '../components/creator';
@import '../components/dish_creator';
@import '../components/payments';
@import '../components/order_result';
@import '../components/delivery';
@import '../components/modals';
@import '../modules/m-split-pizza-list';
@import '../modules/restaurant-menu';
@import '../modules/m-restaurants-overview';
@import '../modules/m-customer-survey';
@import '../modules/m-radio';
@import '../modules/m-checkbox';
@import '../modules/m-select';
@import '../modules/m-modifier-item';
@import '../modules/m-featured-items';
@import '../modules/m-rating';
@import '../modules/m-form';
@import '../modules/m-list';
@import '../modules/m-item';
@import '../modules/m-menu-description';
@import '../modules/m-placeholder';
@import '../modules/m-group-nav';
@import '../modules/m-group';
@import '../modules/m-counter';
@import '../modules/m-availability';
@import '../scope/s-wysiwyg-content';

//
// Shared overrides
// --------------------------------------------------

//
// Smart App Banner
// --------------------------------------------------
.smartbanner {
  z-index: $zindex-smart-app-banner;
}

//
// Form control feedback states
// --------------------------------------------------
.success .help-block,
.has-error .help-block,
.has-error .control-label {
  // Make sure link differs from regular text.
  // If we drop support for IE 11 then `filter: invert(1)` could be another solution.
  a {
    text-decoration: underline; // https://trello.com/c/Rw0XMXDP

    &:hover,
    &:focus{
      text-decoration: none;
    }
  }
}

//
// Lightbox
// --------------------------------------------------
// Improve view with disabled scrolling
.lb-disable-scrolling {
  overflow: hidden;

  .lightbox {
    position: fixed;
    top: 0 !important; // override Lightbox inline styles
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lb-outerContainer {
    margin: 30px auto 0;
  }
}

// Cookie Consent
#c-inr-i {
  overflow-y: auto;
  max-height: calc(100vh - 12em); // Include inner padding (2em), outer margin (2em), and footer height (up to 8em)
}

.cc_div {
  a {
    &:active,
    &:focus,
    &:hover {
      color: inherit;
    }
  }
}

//
// Calendar icon (native date inputs)
// --------------------------------------------------
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

//
// QA A/B tests
// --------------------------------------------------
.l-qa-ab-v1 {
  .qa-ab-hidden-xs {
    @extend .hidden-xs;
  }
}
