$m-form-required-sign-color: red;

// General form styles
.m-form {
  &__group {
    position: relative;
    margin-bottom: $form-group-margin-bottom;

    &:empty {
      display: none;
    }
  }

  &__label {
    font-weight: $labels-font-weight;

    &--required:after {
      content: "*";
      color: $m-form-required-sign-color;

      // TODO: Add delivery widget variant, which doesn't use `m-form__label--required` class, depending on form use case.
      .cart .delivery & {
        display: none;
      }
    }
  }

  &__icon {
    position: relative;
  }

  &__icon-input {
    padding-right: $input-height-base;
  }

  &__icon-btn {
    @extend %button-unstyled;
    @include position(absolute, 0 0 0 null);
    width: $input-height-base;
  }

  // Validation info message.
  &__validation-info {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;

    .success &, // support legacy class name
    .has-success & {
      color: $state-success-text;
    }

    .has-error & {
      color: $state-danger-text;
    }

    &--floating {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 6px;
      padding: 3px 10px;
      font-size: 0.8125rem;
      border-radius: 5px;
      z-index: $zindex-ui;
      box-shadow: 0 5px 6px rgba(#000, .2);

      .success &, // support legacy class name
      .has-success & {
        color: $alert-success-text;
        background-color: $alert-success-bg;
        border-bottom-color: $alert-success-bg;
      }

      .has-error & {
        color: $alert-danger-text;
        background-color: $alert-danger-bg;
        border-bottom-color: $alert-danger-bg;
      }

      &:before {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent; // set a color to make the top arrow visible
        position: absolute;
        top: 0;
        left: 10px;
        transform: translateY(-100%);
        content: "";

        .success &, // support legacy class name
        .has-success & {
          border-bottom-color: $alert-success-bg;
        }

        .has-error & {
          border-bottom-color: $alert-danger-bg;
        }
      }
    }

    &:empty {
      display: none;
    }
  }

  &__info-text {
    margin-bottom: 0;
  }
}

// Temporary styles for card details (TODO: refactor in PureScript required).
.m-card-details {
  display: flex;
  flex-wrap: wrap;
  margin-left: ceil($form-group-margin-bottom / -2);
  margin-right: floor($form-group-margin-bottom / -2);

  .m-form__group {
    padding-left: ceil($form-group-margin-bottom / 2);
    padding-right: floor($form-group-margin-bottom / 2);

    // Card number.
    &:nth-of-type(1) {
      flex-basis: 19em;
    }

    // Name on the card.
    &:nth-of-type(2) {
      flex-basis: 19em;
    }

    &:nth-of-type(3), // Exp year.
    &:nth-of-type(4), // Exp month.
    &:nth-of-type(5) { // CSC.
      flex-basis: 10em;
    }
  }

  .m-form__label {
    white-space: nowrap;
  }
}

// Block-level help text.
.help-block {
  color: inherit; // match the current color of a theme
  opacity: 0.8; // lighten the text for a little contrast

  .has-error &,
  .success &
  .has-success & {
    opacity: 1; // reset the previous contrast (color will be set by status classes)
  }
}

// @deprecated Use `text-[state]` instead, e.g. `text-error`.
.has-error {
  .help-block {
    color: var(--text-danger, #{$state-danger-text});
  }
}

// @deprecated Use `text-[state]` instead, e.g. `text-success`.
.has-success {
  .help-block {
    color: var(--text-success, #{$state-success-text});
  }
}

// Legacy code support.
// TODO: Consider refactoring to a limited container or various modifiers depending on the form contents.
.form-centered {
  max-width: $form-centered-max-width;
  margin-right: auto;
  margin-left: auto;
}

img.payment-method-image-blik {
  display: inline-block;
  height: 3em;
}

img.payment-method-image-card {
  display: inline-block;
  height: 2em;
  padding-right: 2ex;
}

img.payment-method-image-dotpay {
  display: inline-block;
  height: 3em;
}

.has-success .help-block,
.has-success label,
.has-success .control-label,
.has-success .m-form__validation-info {
  color: var(--text-success, #{$state-success-text});
}

.has-success .form-control {
  border-color: var(--text-success, #{$state-success-text});
}

.has-error .help-block,
.has-error label,
.has-error .control-label,
.has-error .m-form__validation-info {
  color: var(--text-danger, #{$state-danger-text});
}

.has-error .form-control {
  border-color: var(--text-danger, #{$state-danger-text});
}

.has-warning .help-block,
.has-warning label,
.has-warning .control-label,
.has-warning .m-form__validation-info {
  color: var(--text-warning, #{$state-warning-text});
}

.has-warning .form-control {
  border-color: var(--text-warning, #{$state-warning-text});
}
