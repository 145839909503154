// Modifier item
// Used in product creator, to display modifier items in ModifierList display mode

// 1. Hardcoded value similar to `creator.scss` or Bootstrap defaults (TODO: consider refactor depending on final design).

.m-modifier-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px; // [1]
  margin-bottom: 10px; // [1]
  align-items: center;
  padding: 4px 15px 4px 0; // [1]

  &__stepper {
    display: inline-flex;
    align-items: center;
  }

  &__count {
    display: inline-block;
    margin-right: .3125em;
    margin-left: .3125em;
    min-width: 1em; // simple fallback for ch unit
    min-width: 1.5ch;
    text-align: center;
  }

  &__btn {
    @extend %button-unstyled;
    font-size: 1.25em;
    font-weight: normal;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]
    min-width: 2em;
    width: 2em;
    height: 2em;
    border-radius: 50%;

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-primary; // in new themes defined in theme.scss.liquid
    }

    &[disabled] {
      opacity: $inactive-opacity;
    }

    .l-mobile-app--ios & {
      color: $text-color;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    width: 100%;

    @media screen and (min-width: $screen-xs-min) {
      width: auto;
      margin: 0 0 0 10px;
    }
  }
}
