$m-radio-size: 20px, 20px !default; // width, height
$m-radio-checked-color: $brand-primary !default;
$m-radio-border: $input-border-shorthand !default;
$m-radio-bg-color: $input-bg !default;
$m-radio-font-weight: inherit !default;

.m-radio {
  padding-left: nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2);
  cursor: pointer;
  font-weight: $m-radio-font-weight;

  &__icon-state {
    position: relative;
    display: inline-block;
    width: nth($m-radio-size, 1);
    height: nth($m-radio-size, 2);
    margin-right: ceil(nth($m-radio-size, 1) / 2);
    margin-left: -(nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2));
    border-radius: 50%;
    vertical-align: middle;
    border: $m-radio-border;
    background-color: $m-radio-bg-color;
  }

  &__input {
    position: absolute;
    opacity: 0; // hide native radio

    &:focus {
      + .m-radio__icon-state {
        border-color: $m-radio-checked-color;
      }
    }

    &:checked {
      + .m-radio__icon-state:after {
        @include position(absolute, 50% null null 50%);
        display: block;
        width: ceil(nth($m-radio-size, 1) / 2);
        height: ceil(nth($m-radio-size, 2) / 2);
        border-radius: 50%;
        background-color: $m-radio-checked-color;
        transform: translateX(-50%) translateY(-50%);
        content: " ";
      }
    }
  }

  &.is-disabled {
    cursor: $cursor-disabled;
    opacity: $inactive-opacity;
  }

  &--center {
    padding-left: 0;

    .m-radio__icon-state {
      margin-right: auto;
      margin-left: auto;
    }
  }

  // 1. Hardcoded value similar to `creator.scss` (TODO: consider refactor depending on final design).
  &--option {
    display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 50px;
    align-items: center;
    padding: 4px 15px 4px (nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2) + 15);
    border-radius: $input-border-radius;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]

    .l-mobile-app--ios & {
      color: $text-color;
    }
  }

  // Special variant for custom extension with icons or any other type of presentation (e.g. star ratings `_star_rating.liquid`).
  // There are no markup requirements except of the `.m-radio--custom .m-radio__input` which prevents `js/utils/Form.js` from transforming regular `radio` for custom-styled `radio`.
  &--custom {
    margin: 0;
    padding: 0;
  }
}

// Adjust Bootstrap 3 styles - START
// @deprecated (use `m-radio--inline` instead)
.radio-inline,
.radio {
  .m-radio {
    padding-left: nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2);
    font-weight: $m-radio-font-weight;

    &__icon-state {
      margin-right: ceil(nth($m-radio-size, 1) / 2);
      margin-left: -(nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2));
    }
  }
}

.radio-inline {
  margin-right: 15px;
  padding-left: 0;

  + .radio-inline {
    margin-left: 0; // reset Bootstrap margin
  }
}
// Adjust Bootstrap 3 styles - END
