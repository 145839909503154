.notification {
  position: fixed;
  padding: 10px 0;
  z-index: $zindex-notification;
  background: #8c2cb1;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  text-decoration: none;
  transition: box-shadow $base-duration, background-color $base-duration;
  font-size: 16px;
  box-shadow: 0 3px 4px rgba(#000, 0.2);

  .l-active-menu.modal-open & {
    // Prevent notification relocation when layout changes.
    visibility: hidden;
  }

  &--static {
    position: relative;
    top: 0;
    bottom: 0;
    box-shadow: none;
  }

  &.hide {
    display: none;
  }

  &__btn {
    @extend %button-unstyled;
    margin-left: 0.5em;
    color: #8c2cb1;
    background-color: #fff;
    border-radius: 4px;
    padding: 0.5em 0.875em;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 90%;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #8c2cb1;
      text-decoration: none;
      opacity: $hover-opacity;
    }

    [class^=icon-] {
      &:before {
        margin-right: -0.5em;
        margin-left: -0.25em;
        vertical-align: baseline;
      }
    }
  }

  &__close {
    @extend %button-unstyled;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin: -5px 10px -5px 0.5em;
    padding: 0;
    width: 40px;
    height: 40px;
    color: inherit;
    border-radius: 50%;
    border: 1px solid rgba(#fff, 0.25);
    cursor: pointer;
    font-size: 16px;
    font-family: sans-serif;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
      opacity: $hover-opacity;
    }
  }

  &--error {
    &#{&} { // override custom settings
      @include alert-variant(#d50000, #d50000, #fff);

      &:target {
        display: none;
      }

      &:hover,
      &:focus {
        color: #fff;
        border-color: #d50000;
        background-color: #d50000;
        box-shadow: none;
      }
    }

    .alert-link {
      font-weight: bold;
    }
  }

  &--payment-warning {
    @include alert-variant(#fff3cd, #fff3cd, #333);

    &:hover,
    &:focus {
      color: #333;
    }
  }
}
