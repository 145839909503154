$delivery-height: 227px;

$cart-background: #fff;
$cart-border: 1px solid gray;
$cart-item-border: 1px dotted lightgray;
$cart-zindex: $zindex-modal !default;
$cart-width: 500px !default;
$cart-price-width: 80px;
$cart-quantity-width: 125px;

.cart-button {
  position: relative;
}

.cart {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: $cart-width;
  // border-radius: 2px;
  // background: $cart-background;
  // border: $cart-border;
  // z-index: $cart-zindex;
  // visibility: hidden;
  // opacity: 0;
  // //transform: translateY(0); //scale3d(0, 0, 0); // transform: translateY(-1000px);
  // transition: visibility 0s linear 0.1s, opacity 0.1s ease-out;//, transform 0.1s ease-out;
  // &.open {
  //   box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.26);
  //   visibility: visible;
  //   opacity: 1;
  //   //transform: translateY(0); // transform: scale3d(1, 1, 1); // transform: translateY(0px);
  //   transition-delay: 0s;
  // }
  // .scroll-view {
  //   position: relative;
  //   &__info {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     pointer-events: none;
  //     z-index: $cart-zindex;
  //     &__shadow {
  //       position: absolute;
  //       width: 100%;
  //       height: 100px;
  //       left: 0;
  //       visibility: hidden;
  //       opacity: 0;
  //       transition: visibility 0s linear 0.5s, opacity 0.5s ease-out;
  //       &--top {
  //         @extend .scroll-view__info__shadow;
  //         top: 0;
  //         box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.75);
  //       }
  //       &--bottom {
  //         @extend .scroll-view__info__shadow;
  //         bottom: 0;
  //         box-shadow: inset 0 -10px 15px -10px rgba(0, 0, 0, 0.75);
  //       }
  //       &.visible {
  //         visibility: visible;
  //         opacity: 1;
  //         transition-delay: 0s;
  //       }
  //     }
  //   }
  // }
  //
  // .scroll {
  //   width: 100%;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   transition: all 0.2s ease-out;
  //   transform: translateZ(0); //webkit scrollbar position bugfix
  //   -webkit-overflow-scrolling: touch;
  // }

  .empty {
    text-align: center;
    font-size: 20px;
    opacity: 0.5;
    padding: 20px;
  }

  ul {
    margin: 0;
  }

  li:last-of-type {
    border-bottom: 0;
  }

  .item {
    min-height: 30px;
    list-style: none;
    padding: 10px 0;
    border-bottom: $cart-item-border;

    &:first-child {
      padding-top: 0;
    }

    .column.info {
      .name {
        font-size: 1.1em;
        font-weight: $font-weight-bold;
      }
      .description {
        margin: 0;
        font-size: floor((100% * $font-size-small / $font-size-base));
      }
    }
    .column--checkbox {
      flex: 0 0 auto;
      text-align: center;

      @media (min-width: $screen-xs-min) {
        width: $cart-quantity-width;
      }
    }
    .column.price {
      display: none;
    }
    .column.subtotal {
      flex: 0 0 auto;
      text-align: right;
      font-weight: $font-weight-bold;

      @media (min-width: $screen-xs-min) {
        display: flex;
        flex: 0 1 auto;
      }
    }

    &.item--inactive > * { // style the child elements to preserve `border` visibility
      opacity: $inactive-opacity;
    }

    &.item--separator {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
    }

    &.item--group {
      border-bottom: none;
    }

    &__quantity {
      width: $cart-quantity-width;

      @media (max-width: ($screen-xs-min - 1)) {
        margin-top: 10px;
        margin-right: -10px;
      }
    }

    &__price {
      text-align: right;

      @media (min-width: $screen-xs-min) {
        width: $cart-price-width;
        order: 10;
      }

      &--promotion {
        width: $cart-price-width;
      }
    }
  }

  .item.error {
    padding: 5px 10px;
    color: white;
    background: #d50000;
    border-bottom: none;
    min-height: 0;
    font-size: 14px;
    &:before {
      font-family: FontAwesome;
      content: "\e813";
      text-decoration: inherit;
      display: inline-block;
      speak: none;
      margin-right: 5px;
    }
  }

  .alert {
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 5px 10px; // the same padding as error item
    }
  }

  .remove-item {
    display: none;
  }

  .coupon-input {
    display: flex;
    flex-wrap: wrap;

    input {
      margin: 0 5px 0 0;
      width: 6.25em;
      max-width: 200px;
      height: auto;
      flex: 1 1 auto;
    }

    .btn {
      margin: 0;
    }
  }

  form {
    margin-bottom: 0;
    input {
      margin-bottom: 0;
      // border: 1px solid #D2D2D2;
    }
    label.check-apt-number {
      flex-grow: 0;
      width: 10px;
      input {
        position: relative;
        top: 29px;
      }
    }
    .multi-column > label {
      margin-right: 10px;
    }
  }


  .modal-header{
    position: relative;
    padding: 6px 20px;
    background: #EDEDED;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);

    .modal-title {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 1.2em;

      &#cart-title {
        text-transform: uppercase;
      }
    }
    .delivery-header { display: none; }
    .clear-cart { font-size: 0.9em; margin-right: 10px; text-transform: none; }

    .close-delivery,
    .close-cart {
      float: right;
      font-size: 2em;
      margin-right: -10px;
      padding-right: 10px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      min-height: 42px;
    }

    .close-delivery {
      display: none;

      + .modal-title {
        display: block; // fixes wrapping bug: https://trello.com/c/nA3nvHAc
      }
    }
  }

  a { cursor: pointer; }

  .actions {
    .delivery-toggle {
      display: none;
    }
  }

  &.delivery-editing {
    .modal-header {
      .clear-cart, .close-cart { display: none; }
      .close-delivery { display: inline; }
      .modal-title { display: none; }
      .delivery-header { display: inline-block; }
    }
    .cart-items {
      max-height: 0 !important;
      transform: scale(0);
    }

    .actions {
      .continue-shopping, .checkout {
        display: none;
      }
      .delivery-toggle {
        display: block;
      }
    }
    .delivery {
      // transform: translateY(-25vh);

      .cost {
        display: inline;
      }
    }
  }

  .delivery {
    position: relative;

    .cost {
      display: none;
    }

    .editing {
      display: none;
      transition: all 0.2s ease-out;

      // margin-top: 20px;
      // margin-bottom: 20px;
    }

    .radio-group {
      @extend .multi-column;
      text-align: center;
      margin-bottom: 15px;
    }

    .button {
      margin-bottom: 0;
    }

    .column {
      &.name {
        flex-grow: 1;
      }
    }

    &.is-editing {
      border-bottom: 0;
      border-top: 0;
      box-shadow: none;

      .editing {
        display: block;
      }
      .delivery-info a {
        // font-size: 1.2em;
        opacity: 0;
      }
      .column {
        &.name {
          flex-grow: 1;
        }
      }
    }

    .delivery-info {
      a {
        font-size: 0.9em;
        opacity: 1;
        transition: all 0.2s ease-out;
      }
    }

    .radio_buttons {
      &.form-group {
        margin-bottom: 0;
      }
    }

    .form-label {
      + .radio {
        margin-top: 0;
      }
    }
  }

  // It is a separate view (not modal), so it needs its own padding.
  .l-mobile-app--ios & {
    margin-top: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }
}

// It should be merged with `m-modifier-item__stepper` as the new standalone component.
.numeric-stepper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: normal;
  height: 32px;
  line-height: 32px;

  @media (min-width: $screen-xs-min) {
    height: 42px;
    line-height: 42px;
  }

  input {
    border: 1px solid rgb(202, 202, 202);
    width: 42px;
    height: inherit;
    line-height: inherit;
    text-align: center;
  }

  a {
    font-size: 22px;
    height: inherit;
    min-width: 36px;
    line-height: inherit;
    font-weight: $font-weight-bold;
    text-decoration: none;

    &:focus {
      opacity: $hover-opacity;
    }

    &:active {
      background-color: rgba(#000, 0.05);
    }
  }

  // Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.cart-multi-column {
  display: flex;

  .column {
    flex: 1 1 0;

    &.info {
      flex: 1 1 auto;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        word-wrap: break-word; /* simple fallback for `hyphens` */
        hyphens: auto;
      }
    }
  }
}

.cart-items {
  &__dish-picture {
    flex: 0 0 auto;
  }

  &__dish-image {
    display: none;
    width: 45px;
    margin-right: floor($grid-gutter-width / 3);
    flex: 0 0 auto;
    align-self: flex-start;
    border-radius: $border-radius-base;

    @media (min-width: $screen-xs-min) {
      display: block;
    }

    @media (min-width: $screen-sm-min) {
      width: 60px;
    }
  }
}

.disable-scroll {
  position: fixed;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

.btn-checkout {
  @extend .btn-primary !optional;
}

@media (max-width: 767px) {
  .cart--modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
