$m-floating-action-bg-color: $brand-primary !default;
$m-floating-action-color: #fff !default;
$m-floating-action-text-color: #999 !default;
$m-floating-action-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !default; // MDC FAB shadow
$m-floating-action-badge-bg-color: $m-floating-action-color !default;
$m-floating-action-btn-size: 65px !default;
$m-floating-action-btn-font-size: 30px !default;

// TODO: Popover readability on mobile when floating action button is visible (z-index or hide on scroll)

.m-floating-action {
  @include position(fixed, null ($grid-gutter-width / 2) ($grid-gutter-height / 2) null);
  z-index: $zindex-floating-action-btn;
  text-align: center;
  color: $m-floating-action-text-color;

  .icon-shopping-cart {
    &:before {
      vertical-align: baseline;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    right: $grid-gutter-width;
    bottom: $grid-gutter-height;
  }

  &__box {
    padding: $grid-gutter-width;
    background-color: $m-floating-action-bg-color;
    box-shadow: $m-floating-action-shadow;
    border-radius: $border-radius-base;
    color: $m-floating-action-color;
    text-align: left;
  }

  &__btn {
    @extend %button-unstyled;
    position: relative;
    z-index: $zindex-floating-action-btn;
    display: inline-block;
    padding: 0;
    width: $m-floating-action-btn-size;
    height: $m-floating-action-btn-size;
    clear: both;
    background-color: $m-floating-action-bg-color;
    box-shadow: $m-floating-action-shadow;
    border-radius: 50%;
    border: 1px solid currentColor;
    line-height: 1;
    color: $m-floating-action-color;
    text-align: center;
    font-size: $m-floating-action-btn-font-size;
    white-space: nowrap;

    &:focus,
    &:hover {
      background-color: darken($m-floating-action-bg-color, 10%);
      color: $m-floating-action-color;
    }

    &:focus {
      @include tab-focus;
    }

    &--text {
      width: auto;
      padding-right: 12px;
      padding-left: 24px;
      border-radius: 40px;
      font-size: 24px;
    }
  }

  &__btn-text {
    font-size: 24px;

    .m-floating-action__btn--icon-only & {
      @include visually-hidden;
    }
  }

  &__list {
    @include position(absolute, null 0 100% null);
    @include list-unstyled;
    z-index: $zindex-floating-action-btn;
    display: none;
    margin: 0;
    animation: fadeIn $base-duration;
  }

  &__list-item {
    float: right;
    clear: right;
    margin-bottom: $grid-gutter-width;
  }

  &__msg {
    &:before {
      @include position(fixed, 0 0 0 0);
      z-index: ($zindex-floating-action-btn - 5);
      background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
      animation: fadeIn $base-duration;
      content: " ";
    }
  }

  &__msg-text {
    position: relative;
    z-index: $zindex-floating-action-btn;
    float: right;
    clear: right;
    max-width: 300px;
    margin-bottom: $grid-gutter-width;
    padding: 0.5em 1em;
    background-color: #fff;
    border-radius: $border-radius-base;

    &:after {
      @include position(absolute, 100% 20px null null);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      content: " ";
    }
  }

  &.is-active {
    .m-floating-action__list {
      display: block;
    }

    .m-floating-action__msg {
      display: none;
    }
  }

  &--overlay {
    &.is-active {
      &:before {
        @include position(fixed, 0 0 0 0);
        z-index: ($zindex-floating-action-btn - 5);
        background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
        animation: fadeIn $base-duration;
        content: " ";
      }
    }
  }

  &__badge {
    @include position(absolute, 0 0 null null);
    display: inline-block;
    padding: 3px 7px;
    font-size: 18px;
    font-weight: $badge-font-weight;
    line-height: $badge-line-height;
    border-radius: $badge-border-radius;
    background-color: $m-floating-action-badge-bg-color;
  }
}
