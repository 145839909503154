// Shared buttons with fixed colors regardless of the theme settings.

// Button that should always work correctly on a dark background.
$btn-light-color: #333 !default;
$btn-light-background: #fff !default;
$btn-light-border: #ccc !default;

.btn-light {
  @include button-variant($btn-light-color, $btn-light-background, $btn-light-border);
}

.btn-text {
  display: inline-block;
  border: 0 none;
  background-color: transparent;
  appearance: none;

  &:focus {
    @include tab-focus;
  }
}
