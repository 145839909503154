.loyalty-dialog {

  text-align: center;
  width: 340px;
  padding-top: 2px;
  padding-bottom: 2px;

  transition: box-shadow .25s ease-in-out;

  &:focus {
    outline: none;
    border-color: green;
    box-shadow: 0 0 50px green;

  }

  .loyalty-badges {
      -moz-border-radius: 5px 5px 0 0;
      -webkit-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
      padding: 10px;
      width: 315px;
  }


  .stamp {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #f3f3f3;
    text-align: center;
    vertical-align: middle;
    border: 2px dashed #ececec;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    & > img {
      border-radius: 500px;
      opacity: 1;
      max-width: 100%;
      transition: all .25s ease-in-out;
    }

    &.empty > img {
      opacity: 0.04;
    }

    &:hover > img {
      box-shadow: 0 0 10px #d10;
    }
  }



  hr {
   border: none;
  }

  hr.special {
      margin-top: 20px;
      margin-bottom: 20px;
      border-top: 2px dashed #d3d3d3;
      border-bottom: none;
  }
  p {
      color: green;
      font-family: 'Helvetica Neue', 'Helvetica', Arial;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
  }

  p.success {
      font-size: 14px;
      color: green;
  }
  p.success i {
      color: green;
      font-size: 50px;
  }
}


.media .loyalty {
  color: white;
  position: absolute;
  text-align: center;
  font-weight: bold;
  z-index: 1;
  text-shadow: 0 1px black;
  top: -2px;
  right: -22px;
  width: 50px;


  & > img {
    position: absolute;
    display: block;
    z-index: -1;
    top: -17px;
    background: white;
    -moz-border-radius: 500px;
    -webkit-border-radius: 500px;
    border-radius: 500px;
  }

}

div.loyalty-widget {
    padding: 10px;
    border: 2px dashed #f3f3f3;
    text-align: center;
    margin-bottom: 15px;
    background: white;
    color: #333;
}
.loyalty-widget h4 {
    text-align: center;;
    color: inherit;
    margin-bottom: 15px;
}
.loyalty-widget  .stamp {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #f3f3f3;
    text-align: center;
    vertical-align: middle;
    border: 2px dashed #ececec;

}
.loyalty-widget .stamp img {
    width: 100%;
}

.loyalty-widget .stamp.empty img {
    opacity: 0.2;
}
