$m-group-nav-font-size: null !default;
$m-group-nav-font-weight: null !default;
$m-group-nav-dropdown-shadow: 0 6px 12px rgba(#000, 0.175) !default;
$m-group-nav-dropdown-base-width: 250px !default;
$m-group-nav-dropdown-max-width: 60vw !default;
$m-group-nav-dropdown-white-space: normal !default;
$m-group-nav-background: transparent !default;
$m-group-nav-text-transform: null !default;
$m-group-nav-aside-link-border: null !default;
$m-group-nav-sticky-zindex: $zindex-ui - 10 !default; // in most cases it should be lower than other elements in the stacking context in order to avoid possible overlap bugs (example bug: https://trello.com/c/X5ahrhR5)

// Title
$m-group-nav-title-padding: null !default;
$m-group-nav-title-font-size: null !default;

// List
$m-group-nav-list-is-unstyled: true !default;

// Link
$m-group-nav-link-padding: 0.7em !default;
$m-group-nav-link-color: inherit !default;
$m-group-nav-link-background: transparent !default;

// Link hover
$m-group-nav-link-hover-color: inherit !default;
$m-group-nav-link-hover-background: transparent !default;
$m-group-nav-link-hover-font-weight: null !default;

.m-group-nav {
  background: $m-group-nav-background;
  font-size: $m-group-nav-font-size;
  font-weight: $m-group-nav-font-weight;
  text-transform: $m-group-nav-text-transform;

  &__title {
    margin-top: 0;
    padding-top: $m-group-nav-title-padding;
    padding-bottom: $m-group-nav-title-padding;
    font-size: $m-group-nav-title-font-size;
  }

  &__list {
    @if ($m-group-nav-list-is-unstyled == true) {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &--horizontal {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      text-align: center;
    }

    &--dropdown {
      @include list-unstyled; // override default ul
      @include position(absolute, 100% null null 0);
      z-index: $zindex-dropdown;
      display: none; // none by default, but block on "open" of the menu
      float: left;
      overflow: auto;
      margin-top: 2px;
      width: $m-group-nav-dropdown-base-width;
      max-width: $m-group-nav-dropdown-max-width;
      max-height: 80vh;
      white-space: $m-group-nav-dropdown-white-space;
      border-radius: $border-radius-base;
      background-color: $dropdown-bg;
      box-shadow: $m-group-nav-dropdown-shadow;
    }

    &--dropdown-menu-right {
      right: 0;
      left: auto;
    }
  }

  &__link {
    display: block;
    padding-top: $m-group-nav-link-padding;
    padding-bottom: $m-group-nav-link-padding;
    background: $m-group-nav-link-background;
    color: $m-group-nav-link-color;
    border: none;

    &.is-active,
    &:hover,
    &:focus {
      background: $m-group-nav-link-hover-background;
      color: $m-group-nav-link-hover-color;
      text-decoration: none;
      font-weight: $m-group-nav-link-hover-font-weight;
    }

    &.is-unavailable {
      opacity: 0.5
    }

    &--dropdown-toggle {
      border: 0; // override default button
      background: none; // override default button
      font-size: inherit; // override default button

      &.is-active,
      &:hover,
      &:focus {
        background: none;
      }
    }

    .m-group-nav__list--horizontal & {
      padding-left: $m-group-nav-link-padding;
      padding-right: $m-group-nav-link-padding;
    }
  }

  &__list-item {
    .m-group-nav__list--horizontal & {
      flex: 1 1 auto;
    }
  }

  &__link-image {
    display: block;
    margin: 0 auto 0.5em;
    border-radius: $border-radius-base;
  }

  &--not-mobile {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &--sticky {
    max-width: 100%;
    z-index: $m-group-nav-sticky-zindex;
  }

  &--dropdown {
    position: relative;

    &.open {
      .m-group-nav__list {
        display: block;
      }
    }
  }

  &--aside {
    .m-group-nav__link {
      border-top: $m-group-nav-aside-link-border;
    }
  }
}
